import sanitizeHtml from 'sanitize-html'

export function debounce(func, timeout = 300) {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func.apply(this, args)
        }, timeout)
    }
}

// const parseDateString = (date) => {
//     const year = date.substring(0, 4);
//     const month = date.substring(4, 6);
//     const day = date.substring(6, 8);
//     return `${day}.${month}.${year}`
// }
//
// const parseTimeString = (time) => {
//     const cleanTime = time.split(".")[0];
//
//     const hour = cleanTime.substring(0, 2);
//     const minutes = cleanTime.substring(2, 4);
//     const seconds = cleanTime.substring(4, 6);
//
//     return `${hour}:${minutes}:${seconds}`
// }

export const returnFullNameFromUserObject = (user) => {
    if (user) {
        return `${user.lastName} ${user.firstName} ${
            user.middleName ? user.middleName : ''
        }`
    } else {
        return '-'
    }
}

export const formatVerseText = (verseText) => {
    let output = verseText

    output = output.replaceAll('<enter>', '<br/>')

    output = output.replaceAll('<note>', '<i>')
    output = output.replaceAll('</note>', '</i>')

    let remarks = output.match(/<remark.*?<\/remark>/g)

    if (remarks) {
        remarks = remarks.map((remark) => {
            return sanitizeHtml(remark)
        })
    }

    output = output.replaceAll(/<remark.*?<\/remark>/g, '')

    return { text: output, remarks }
}

export const cleanVerseText = (verseText) => {
    let output = verseText

    output = output.replaceAll('<enter>', '\n')

    output = output.replaceAll('<note>', ' ')
    output = output.replaceAll('</note>', '')

    output = output.replaceAll('<remark>', ' ')
    output = output.replaceAll('</remark>', '')

    return output
}
