export const PROFILE = 'profile'
export const ACCESS_TOKEN_KEY = 'frontend-access-token'
export const REFRESH_TOKEN_KEY = 'frontend-refresh-token'
export const ACCESS_TOKEN_EXPIRES_AT = 'frontend-access-token-expires-at'
export const REFRESH_TOKEN_EXPIRES_AT = 'frontend-refresh-token-expires-at'
export const API_ROOT = `${
    process.env.REACT_APP_BACKEND_URL || 'https://bvs-api.issart.com'
}/api/v1`
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm'
export const ISO_DATE_FORMAT_UTC = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
export const CALENDAR_EVENT_TYPES = ['holiday']
export const SCHEDULE_EVENT_TYPES = ['now', 'once', 'yearly', 'weekly', 'daily']
