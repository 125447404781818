import type { Language, LocaleValue } from 'interfaces'

const getLocaleText = (value: string | LocaleValue, locale: Language): string | undefined => {
    let data: LocaleValue = {}
    try {
        data = typeof value === 'string' ? JSON.parse(value) : value
    } catch (error) {
        // Just swallowing errors
    }

    return data ? data[locale] ?? Object.values(data)[0] : undefined
}

export default getLocaleText
