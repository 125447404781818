import { type FunctionComponent, type ReactNode } from 'react'
import { IResourceComponentsProps, usePermissions, useTranslate } from '@pankod/refine-core'
import {
    List,
    Table,
    TextField,
    useTable,
    DateField,
    Space,
    EditButton,
    DeleteButton,
    ShowButton,
    BooleanField,
} from '@pankod/refine-antd'
import { useTranslation } from 'react-i18next'

import { DATE_FORMAT } from '../../constants'

import getLocaleText from '../../utils/getLocaleText'
import type { ICalendarEvent, Language } from '../../interfaces'
import { Roles } from '../../interfaces/roles'

type Column = {
    name: keyof ICalendarEvent | 'actions'
    title?: string
    render: (value: any, item: ICalendarEvent) => ReactNode
}

export const CalendarEventList: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate()
    const { i18n } = useTranslation()
    const { data: permissions } = usePermissions()
    const { tableProps } = useTable<ICalendarEvent>({
        initialSorter: [{ field: 'id', order: 'desc' }],
    })

    const currentLanguage: Language = i18n.language as Language
    const isAdmin = !!permissions?.includes(Roles.ADMIN)

    const columns: Column[] = [
        { name: 'name', render: (value) => <TextField value={value} /> },
        {
            name: 'eventType',
            render: (value) => <TextField value={t(`calendarEvents.eventTypes.${value}`, undefined, '-')} />,
        },
        {
            name: 'title',
            render: (_value, item) => <TextField value={getLocaleText(item.title, currentLanguage) ?? ''} />,
        },
        // { name: 'description', render: (_value, item) => <TextField value={getLocaleText(item.description, currentLanguage) ?? ''} /> },
        {
            name: 'dateStart',
            render: (value) => (value ? <DateField value={value ? value : ''} format={DATE_FORMAT} /> : '-'),
        },
        {
            name: 'dateEnd',
            render: (value) => (value ? <DateField value={value ? value : ''} format={DATE_FORMAT} /> : '-'),
        },
        {
            name: 'createdAt',
            render: (value) => (value ? <DateField value={value ? value : ''} format={DATE_FORMAT} /> : '-'),
        },
        {
            name: 'updatedAt',
            render: (value) => (value ? <DateField value={value ? value : ''} format={DATE_FORMAT} /> : '-'),
        },
        {
            name: 'deletedAt',
            render: (value) => (value ? <DateField value={value ? value : ''} format={DATE_FORMAT} /> : '-'),
        },
        { name: 'isActive', render: (value) => <BooleanField value={value} /> },
        {
            name: 'actions',
            title: t('table.actions'),
            render: (_value, record) => (
                <Space>
                    <ShowButton hideText size="small" recordItemId={record.id} />
                    {isAdmin && <EditButton hideText size="small" recordItemId={record.id} />}
                    {isAdmin && <DeleteButton hideText size="small" recordItemId={record.id} />}
                </Space>
            ),
        },
    ]

    return (
        <List canCreate={isAdmin}>
            <Table {...tableProps} rowKey="id">
                {columns.map(({ name, title, render }) => (
                    <Table.Column
                        dataIndex={name}
                        key={name}
                        title={title ?? t(`calendarEvents.item.${name}`)}
                        render={render}
                    />
                ))}
            </Table>
        </List>
    )
}
