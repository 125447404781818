import { FC, Key, useState } from 'react'
import {
    Button,
    Col,
    Icons,
    notification,
    Row,
    Table,
    TextField,
    Tooltip,
    Typography,
} from '@pankod/refine-antd'
import { useLocalStorage, useVerseCopy } from '../../hooks'
import { IFavoriteVerse, ISearchResult } from '../../interfaces'
import {
    useGetIdentity,
    useNavigation,
    useTranslate,
} from '@pankod/refine-core'
import { formatVerseText } from '../../utils'
import sanitizeHtml from 'sanitize-html'

const { Title } = Typography

const FavoritesList: FC = () => {
    const t = useTranslate()
    const navigation = useNavigation()
    const { data } = useGetIdentity()

    const [storedVerses, setStoredVerses] = useLocalStorage<{
        verses: Array<IFavoriteVerse>
    }>(`${data.id}-verses`, { verses: [] })
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
    const { copyVerseToClipBoard, multipleVerseCopyToClipBoard } =
        useVerseCopy()

    const onVerseDelete = (id: string) => {
        setStoredVerses({
            verses: storedVerses.verses.filter(
                (storedVerse: IFavoriteVerse) => storedVerse.id !== id
            ),
        })
        notification.open({
            message: t('notifications.versesRemoveSuccess'),
            type: 'success',
        })
    }

    const onMultipleVerseDelete = () => {
        setStoredVerses({
            verses: storedVerses.verses.filter(
                (storedVerse: IFavoriteVerse) =>
                    !selectedRowKeys.includes(storedVerse.id)
            ),
        })
        setSelectedRowKeys([])
        notification.open({
            message: t('notifications.versesRemoveSuccess'),
            type: 'success',
        })
    }

    const onMultipleVerseCopy = async () => {
        const verses: IFavoriteVerse[] = selectedRowKeys
            .map((id) =>
                storedVerses.verses.find(
                    (storedVerse: IFavoriteVerse) => storedVerse.id === id
                )
            )
            .filter((verse: IFavoriteVerse | undefined) => verse !== undefined)
        await multipleVerseCopyToClipBoard(verses)
    }

    const onSelectChange = (newSelectedRowKeys: Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    return (
        <Row
            style={{
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '6px',
                padding: '14px 24px 16px 24px',
            }}
        >
            <Col xs={24}>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Title level={3}>{t('favorites.title')}</Title>
                    {selectedRowKeys.length > 0 &&
                        storedVerses.verses.length > 0 && (
                            <div>
                                <Button
                                    onClick={onMultipleVerseCopy}
                                    style={{ marginRight: '8px' }}
                                    icon={<Icons.CopyOutlined />}
                                >
                                    {t('buttons.copy')} (
                                    {selectedRowKeys.length})
                                </Button>
                                <Button
                                    onClick={onMultipleVerseDelete}
                                    icon={<Icons.CloseOutlined />}
                                >
                                    {t('buttons.delete')} (
                                    {selectedRowKeys.length})
                                </Button>
                            </div>
                        )}
                </div>
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        onChange: onSelectChange,
                        selectedRowKeys,
                    }}
                    bordered
                    pagination={{ hideOnSinglePage: true }}
                    size="small"
                    dataSource={storedVerses.verses}
                    rowKey="id"
                >
                    <Table.Column
                        dataIndex="text"
                        key="text"
                        title={t('favorites.fields.text')}
                        render={(value) => {
                            const formattedVerseText = formatVerseText(value)
                            return (
                                <>
                                    {' '}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(
                                                formattedVerseText.text
                                            ),
                                        }}
                                    />
                                    {formattedVerseText.remarks &&
                                        formattedVerseText.remarks.map(
                                            (remark: string) => {
                                                return (
                                                    <Tooltip
                                                        key={remark}
                                                        title={remark}
                                                    >
                                                        <span
                                                            style={{
                                                                marginLeft:
                                                                    '4px',
                                                                cursor: 'help',
                                                            }}
                                                        >
                                                            (*)
                                                        </span>
                                                    </Tooltip>
                                                )
                                            }
                                        )}
                                </>
                            )
                        }}
                    />
                    <Table.Column
                        align="center"
                        dataIndex="book"
                        key="book"
                        title={t('favorites.fields.bookName')}
                        render={(value) => <TextField value={value.name} />}
                    />
                    <Table.Column
                        align="center"
                        dataIndex="bookNumber"
                        key="bookNumber"
                        title={t('favorites.fields.bookNumber')}
                        render={(value) => <TextField value={value} />}
                    />
                    <Table.Column
                        align="center"
                        dataIndex="chapterNumber"
                        key="chapterNumber"
                        title={t('favorites.fields.chapterNumber')}
                        render={(value) => <TextField value={value} />}
                    />
                    <Table.Column
                        align="center"
                        dataIndex="verseNumber"
                        key="verseNumber"
                        title={t('favorites.fields.verseNumber')}
                        render={(value) => <TextField value={value} />}
                    />
                    <Table.Column<ISearchResult>
                        align="center"
                        title={t('table.actions')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                }}
                            >
                                <Button
                                    style={{ marginRight: '8px' }}
                                    size="small"
                                    icon={<Icons.SearchOutlined />}
                                    onClick={() =>
                                        navigation.push(
                                            `/bibles/show/${record.book.bibleId}/book/${record.book.id}?chapter=${record.chapterNumber}&verseId=${record.id}`
                                        )
                                    }
                                />
                                <Button
                                    onClick={() => copyVerseToClipBoard(record)}
                                    style={{ marginRight: '8px' }}
                                    size="small"
                                    icon={<Icons.CopyOutlined />}
                                />
                                <Button
                                    onClick={() => onVerseDelete(record.id)}
                                    size="small"
                                    icon={<Icons.CloseOutlined />}
                                />
                            </div>
                        )}
                    />
                </Table>
            </Col>
        </Row>
    )
}

export default FavoritesList
