import { type FunctionComponent, useState, useEffect, useCallback } from 'react'
import { Tabs } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import ReactMde from 'react-mde'

import textToLocaleValue from '../../../utils/textToLocaleValue'
import type { Language, LocaleValue } from '../../../interfaces'

const { TabPane } = Tabs

type Props = {
    value?: string | LocaleValue
    onChange?: (value?: LocaleValue) => void
}

const LocaleTextArea: FunctionComponent<Props> = ({ value, onChange }) => {
    const { i18n } = useTranslation()
    const t = useTranslate()
    const [innerValue, setInnerValue] = useState<LocaleValue | undefined>(undefined)
    const [descriptionTab, setDescriptionTab] = useState<'write' | 'preview'>('write')

    const languages = i18n.languages ?? []

    useEffect(() => {
        if (typeof value === 'string') {
            const parsedValue = textToLocaleValue(value)
            setInnerValue(parsedValue)
            onChange?.(parsedValue)
        } else if (typeof value === 'object' && value != null) {
            setInnerValue((prev) => (prev === value ? prev : value))
        }
    }, [value, onChange])

    const onInnerChange = useCallback(
        (locale: Language, value?: string) => {
            setInnerValue((prevValue) => {
                const nextValue = !!prevValue && typeof prevValue === 'object' ? { ...prevValue } : {}
                nextValue[locale] = value

                onChange?.(nextValue)
                return nextValue
            })
        },
        [onChange]
    )

    return (
        <Tabs>
            {languages.map((language) => (
                <TabPane key={language} tab={t(`languages.${language}`)}>
                    <ReactMde
                        selectedTab={descriptionTab}
                        onTabChange={setDescriptionTab}
                        generateMarkdownPreview={async (markdown) => <ReactMarkdown>{markdown}</ReactMarkdown>}
                        value={innerValue?.[language as Language]}
                        onChange={(newValue) => onInnerChange(language as Language, newValue)}
                    />
                </TabPane>
            ))}
        </Tabs>
    )
}

export default LocaleTextArea
