import {FC, useEffect, useMemo, useRef, useState} from 'react'
import {useParams} from 'react-router'
import lodash from 'lodash'
import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    Icons,
    notification,
    Result,
    Row,
    Space,
    Spin, Tooltip,
    Typography,
} from '@pankod/refine-antd'
import {ExceptionStatusType} from 'antd/lib/result'
import {
    useCustom,
    useGetIdentity,
    useNavigation,
    useTranslate,
} from '@pankod/refine-core'
import sanitizeHtml from 'sanitize-html';

import {IBook, IFavoriteVerse, IVerse} from '../../../interfaces'
import {API_ROOT} from '../../../constants'

import styles from './style.module.css'
import {useLocalStorage, useVerseCopy} from '../../../hooks'
import {formatVerseText} from "../../../utils";

const Loader: FC = () => {
    const t = useTranslate()

    return (
        <div className={styles.centeredContainer}>
            <Spin spinning size="large"/>
            <Typography.Title level={5} style={{marginTop: '8px'}}>
                {t('book.loading')}...
            </Typography.Title>
        </div>
    )
}

const Error: FC<{
    title: string
    status?: ExceptionStatusType
    subTitle?: string
}> = ({title, status, subTitle}) => {
    const navigation = useNavigation()
    const t = useTranslate()

    return (
        <div className={styles.centeredContainer}>
            <Result
                status={status || 500}
                title={title}
                subTitle={subTitle || t('pages.error.general')}
                extra={
                    <Button onClick={navigation.goBack} type="primary">
                        {t('buttons.back')}
                    </Button>
                }
            />
        </div>
    )
}

const BookView: FC = () => {
    const listRef = useRef<null | HTMLUListElement>(null)
    const {data: userData} = useGetIdentity()

    const [storedVerses, setStoredVerses] = useLocalStorage<{
        verses: Array<IFavoriteVerse>
    }>(`${userData.id}-verses`, {verses: []})
    const {multipleVerseCopyToClipBoard} = useVerseCopy()

    const {bibleId, bookId} = useParams()
    const {chapter, verseId} = Object.fromEntries(
        new URLSearchParams(window.location.search)
    )
    const navigation = useNavigation()
    const t = useTranslate()

    const {isLoading, isError, data} = useCustom<IBook>({
        url: `${API_ROOT}/bibles/${bibleId}/books/${bookId}`,
        method: 'get',
    })

    const groupedVerses = useMemo(() => {
        return lodash.groupBy(data?.data.verses, 'chapterNumber')
    }, [data])

    const chapters = useMemo(() => {
        return Object.keys(groupedVerses).map((chapter) =>
            Number.parseInt(chapter)
        )
    }, [groupedVerses])

    const [selectedChapter, setSelectedChapter] = useState(
        chapter ? Number.parseInt(chapter) : 1
    )
    const [selectedVerses, setSelectedVerses] = useState<Array<string>>(
        verseId ? [verseId] : []
    )
    const [displayedVerse, setDisplayedVerse] = useState<string | undefined>(
        verseId
    )

    useEffect(() => {
        const listElement = document.getElementById(verseId)
        if (listElement) {
            listElement.scrollIntoView()
        }
    }, [verseId, isLoading])

    useEffect(() => {
        if (displayedVerse) {
            const listElement = document.getElementById(displayedVerse)
            if (listElement) {
                listElement.scrollIntoView()
            }
            setDisplayedVerse(undefined)
        }
    }, [displayedVerse, isLoading])

    const onChapterClick = (chapter: number) => {
        setSelectedChapter(chapter)
        listRef.current?.scroll({top: 0})
    }

    const onNextChapterClick = () => {
        setSelectedChapter((prevState) => prevState + 1)
        listRef.current?.scroll({top: 0})
    }

    const onPreviousChapterClick = () => {
        setSelectedChapter((prevState) => prevState - 1)
        listRef.current?.scroll({top: 0})
    }

    const onVerseSelect = (id: string) => {
        if (selectedVerses.includes(id)) {
            setSelectedVerses((prevState) =>
                prevState.filter((ids) => ids !== id)
            )
        } else {
            setSelectedVerses((prevState) => [...prevState, id])
        }
    }

    const onVerseShow = (verse: IVerse) => {
        setSelectedChapter(verse.chapterNumber)
        setDisplayedVerse(verse.id)
    }

    const onDeselectVerse = (verseId: string) => {
        if (selectedVerses.length > 0) {
            setSelectedVerses((prevState) =>
                prevState.filter((currentVerseId) => currentVerseId !== verseId)
            )
        }
    }

    const onDeselectVerses = () => {
        setSelectedVerses([])
    }

    const onVersesCopyClick = async () => {
        if (data) {
            const verses = selectedVerses
                .map((verseId) =>
                    data.data.verses.find((verse) => verse.id === verseId)
                )
                .filter((verse) => verse !== undefined) as Array<IVerse>
            const convertedVerse: Array<IFavoriteVerse> = verses.map(
                (verse) => ({
                    ...verse,
                    book: {
                        id: bookId as string,
                        bookNumber: data.data.bookNumber,
                        shortName: data.data.shortName,
                        bookTitle: data.data.bookTitle,
                        name: data.data.name,
                        bibleId: bibleId as string,
                    },
                })
            )
            await multipleVerseCopyToClipBoard(convertedVerse)
        }
    }

    const onFavoriteVerseChange = (verse: IFavoriteVerse) => {
        if (
            storedVerses.verses.find(
                (storedVerse: IFavoriteVerse) => storedVerse.id === verse.id
            )
        ) {
            setStoredVerses({
                verses: storedVerses.verses.filter(
                    (storedVerse: IVerse) => storedVerse.id !== verse.id
                ),
            })
            notification.open({
                message: t('notifications.versesRemoveSuccess'),
                type: 'error',
            })
        } else {
            setStoredVerses({verses: [...storedVerses.verses, verse]})
            notification.open({
                message: t('notifications.versesAddSuccess'),
                type: 'success',
            })
        }
    }

    return (
        <div className={styles.contentContainer}>
            {isLoading && <Loader/>}
            {isError && <Error title={t('book.error')}/>}
            {!isLoading && !isError && data && (
                <div className={styles.dataContainer}>
                    <Row>
                        <Col xs={2}>
                            <Button
                                icon={<Icons.ArrowLeftOutlined/>}
                                onClick={navigation.goBack}
                                type="link"
                            >
                                {t('buttons.back')}
                            </Button>
                        </Col>
                        <Col
                            xs={22}
                            style={{width: '100%', textAlign: 'center'}}
                        >
                            <Typography.Title style={{margin: 0}}>
                                {data.data.bookTitle}
                            </Typography.Title>
                            <Typography.Title
                                level={5}
                                style={{marginTop: 0}}
                            >
                                {t('book.title')} {data.data.bookNumber}
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Row style={{flexGrow: 1}}>
                        <Col xs={4} className={styles.contents}>
                            <Typography.Title level={5}>
                                {t('book.contents')}
                            </Typography.Title>
                            <ul className={styles.chaptersList}>
                                {chapters.map((chapter) => (
                                    <li key={chapter}>
                                        <Button
                                            disabled={
                                                chapter === selectedChapter
                                            }
                                            type="link"
                                            onClick={() =>
                                                onChapterClick(chapter)
                                            }
                                        >
                                            {t('book.chapter')} {chapter}
                                        </Button>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                        <Col xs={20} className={styles.versesContainer}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography.Title level={5}>
                                    {t('book.chapter')} {selectedChapter}
                                </Typography.Title>
                                {selectedVerses.length > 0 && (
                                    <div>
                                        <Dropdown
                                            arrow
                                            placement="bottomLeft"
                                            overlay={
                                                <ul
                                                    className={
                                                        styles.selectedVersesList
                                                    }
                                                >
                                                    {selectedVerses.map(
                                                        (selectedVerse) => {
                                                            const originalVerse =
                                                                data.data.verses.find(
                                                                    (verse) =>
                                                                        verse.id ===
                                                                        selectedVerse
                                                                )
                                                            if (originalVerse) {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            selectedVerse
                                                                        }
                                                                        className={
                                                                            styles.selectedVersesListItem
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            style={{
                                                                                marginRight:
                                                                                    '8px',
                                                                            }}
                                                                        >
                                                                            {`${data.data.name} ${originalVerse.chapterNumber}:${originalVerse.verseNumber}`}
                                                                        </Typography>
                                                                        <div>
                                                                            <Button
                                                                                onClick={() =>
                                                                                    onVerseShow(
                                                                                        originalVerse
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    marginRight:
                                                                                        '8px',
                                                                                }}
                                                                                ghost
                                                                                type="primary"
                                                                                size="small"
                                                                                icon={
                                                                                    <Icons.EyeOutlined/>
                                                                                }
                                                                            />
                                                                            <Button
                                                                                onClick={() =>
                                                                                    onDeselectVerse(
                                                                                        selectedVerse
                                                                                    )
                                                                                }
                                                                                ghost
                                                                                type="primary"
                                                                                size="small"
                                                                                icon={
                                                                                    <Icons.StopOutlined/>
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                )
                                                            } else {
                                                                return null
                                                            }
                                                        }
                                                    )}
                                                </ul>
                                            }
                                            trigger={['click']}
                                        >
                                            <Button
                                                ghost
                                                type="primary"
                                                size="small"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <Space>
                                                    {`${t(
                                                        'verses.selected'
                                                    )}: ${
                                                        selectedVerses.length
                                                    }`}
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                        <Button
                                            style={{
                                                marginRight: '8px',
                                                marginLeft: '8px',
                                            }}
                                            onClick={onDeselectVerses}
                                            ghost
                                            size="small"
                                            type="primary"
                                            icon={<Icons.StopOutlined/>}
                                        >
                                            {t('buttons.deselectAll')}
                                        </Button>
                                        <Button
                                            onClick={onVersesCopyClick}
                                            ghost
                                            size="small"
                                            type="primary"
                                            icon={<Icons.CopyOutlined/>}
                                        >
                                            {t('buttons.copyVerses')}
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <ul ref={listRef} className={styles.versesList}>
                                {groupedVerses[selectedChapter].map((verse) => {
                                    const isVerseFavorite =
                                        !!storedVerses.verses.find(
                                            (sVerse: IVerse) =>
                                                sVerse.id === verse.id
                                        )
                                    const convertedVerse: IFavoriteVerse = {
                                        ...verse,
                                        book: {
                                            id: data?.data.id,
                                            bookNumber: data?.data.bookNumber,
                                            name: data?.data.name,
                                            bookTitle: data?.data.bookTitle,
                                            shortName: data?.data.shortName,
                                            bibleId: data?.data.bibleId,
                                        },
                                    }

                                    const formattedVerseText = formatVerseText(verse.text);

                                    return (
                                        <li
                                            style={{
                                                fontWeight:
                                                    selectedVerses.includes(
                                                        verse.id
                                                    )
                                                        ? 'bold'
                                                        : undefined,
                                            }}
                                            className={styles.verseListItem}
                                            id={verse.id}
                                            key={verse.id}
                                        >
                                            <Checkbox
                                                onClick={() =>
                                                    onVerseSelect(verse.id)
                                                }
                                                checked={selectedVerses.includes(
                                                    verse.id
                                                )}
                                            />
                                            <span
                                                style={{
                                                    fontWeight: 'bold',
                                                    marginLeft: '8px',
                                                    marginRight: '8px',
                                                    minWidth: '20px'
                                                }}
                                            >
                                                    {verse.verseNumber}.
                                                </span>
                                            <span
                                                dangerouslySetInnerHTML={{__html: sanitizeHtml(formattedVerseText.text)}}/>
                                            {formattedVerseText.remarks && formattedVerseText.remarks.map((remark: string) =>
                                            {
                                                return <Tooltip key={remark} title={remark}><span
                                                    style={{marginLeft: '4px', cursor: 'help'}}>(*)</span></Tooltip>
                                            })}
                                            <Button
                                                onClick={() =>
                                                    onFavoriteVerseChange(
                                                        convertedVerse
                                                    )
                                                }
                                                className={
                                                    isVerseFavorite
                                                        ? styles.visibleButton
                                                        : undefined
                                                }
                                                style={{
                                                    margin: 0,
                                                    padding: 0,
                                                }}
                                                size="small"
                                                type="link"
                                                icon={
                                                    isVerseFavorite ? (
                                                        <Icons.StarFilled/>
                                                    ) : (
                                                        <Icons.StarOutlined/>
                                                    )
                                                }
                                            />
                                        </li>
                                    )
                                })}
                            </ul>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: 'auto',
                                }}
                            >
                                <Button
                                    type="primary"
                                    onClick={onPreviousChapterClick}
                                    disabled={selectedChapter === 1}
                                >
                                    {t('buttons.previousChapter')}
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={onNextChapterClick}
                                    disabled={
                                        selectedChapter >=
                                        chapters[chapters.length - 1]
                                    }
                                >
                                    {t('buttons.nextChapter')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    )
}

export default BookView
