import { FC } from 'react'
import {
    IResourceComponentsProps,
    usePermissions,
    useTranslate,
} from '@pankod/refine-core'
import {
    List,
    Table,
    TextField,
    useTable,
    DateField,
    Space,
    DeleteButton,
} from '@pankod/refine-antd'
import { ISettings } from 'interfaces'
import { Roles } from 'interfaces/roles'
import { DATE_FORMAT } from '../../constants'

export const SettingsList: FC<IResourceComponentsProps> = () => {
    const t = useTranslate()

    const { tableProps } = useTable<ISettings>({
        initialSorter: [
            {
                field: 'id',
                order: 'desc',
            },
        ],
    })

    const { data: permissionsData } = usePermissions()

    return (
        <List>{/* canCreate={permissionsData?.includes(Roles.ADMIN)} */}
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex="parameterKey"
                    key="parameterKey"
                    title={t('settings.fields.parameterKey')}
                    render={(value) => <TextField value={value} />}
                />
                <Table.Column
                    dataIndex="parameterValue"
                    key="parameterValue"
                    title={t('settings.fields.parameterValue')}
                    render={(value) => <TextField value={value} />}
                />
                <Table.Column
                    dataIndex="createdAt"
                    key="createdAt"
                    title={t('settings.fields.createdAt')}
                    render={(value) =>
                        value ? (
                            <DateField
                                value={value ? value : ''}
                                format={DATE_FORMAT}
                            />
                        ) : (
                            '-'
                        )
                    }
                />
                <Table.Column
                    dataIndex="updatedAt"
                    key="updatedAt"
                    title={t('settings.fields.updatedAt')}
                    render={(value) =>
                        value ? (
                            <DateField
                                value={value ? value : ''}
                                format={DATE_FORMAT}
                            />
                        ) : (
                            '-'
                        )
                    }
                />
                <Table.Column<ISettings>
                    title={t('table.actions')}
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            {/* {permissionsData?.includes(Roles.ADMIN) && (
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            )} */}
                            {permissionsData?.includes(Roles.ADMIN) && (
                                <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            )}
                        </Space>
                    )}
                />
            </Table>
        </List>
    )
}
