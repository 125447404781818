import { FC } from 'react'
import routerProvider from '@pankod/refine-react-router-v6'
import { TitleProps } from '@pankod/refine-core'

const { Link } = routerProvider

export const Title: FC<TitleProps> = ({ collapsed }) => {
    return (
        <Link
            style={{ display: 'flex', justifyContent: 'center' }}
            to="/bibles"
        >
            {collapsed ? (
                <img
                    src={'/images/logo.webp'}
                    alt="Refine"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '12px 24px',
                        height: '60px',
                    }}
                />
            ) : (
                <img
                    src={'/images/logo.webp'}
                    alt="Refine"
                    style={{
                        width: '150px',
                        padding: '12px 24px',
                    }}
                />
            )}
        </Link>
    )
}
