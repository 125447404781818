import { FunctionComponent, type ReactNode, useCallback } from 'react'
import { useTranslate, IResourceComponentsProps, type HttpError } from '@pankod/refine-core'
import { Create, Form, Input, DatePicker, Select, Checkbox, useForm } from '@pankod/refine-antd'
import { type FormItemProps } from 'antd/lib/form/FormItem'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'

import 'react-mde/lib/styles/css/react-mde-all.css'

import { ISO_DATE_FORMAT_UTC, DATE_FORMAT, SCHEDULE_EVENT_TYPES } from '../../constants'

import LocaleTextField from '../../components/common/LocaleTextField'
import LocaleTextArea from '../../components/common/LocaleTextArea'

import type { INotificationEvent, LocaleValue } from '../../interfaces'

dayjs.extend(utc)

const { RangePicker } = DatePicker

const i18nBase = 'notificationEvents.form'

type FormValues = Omit<INotificationEvent, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt'> & {
    // dateRange?: [Dayjs, Dayjs]
    title?: INotificationEvent['title']
    description?: INotificationEvent['title']
}

type FieldData = FormItemProps<FormValues> & {
    name: keyof FormValues
    field: ReactNode
}

export const NotificationEventCreate: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate()
    const { formProps, saveButtonProps } = useForm<FormValues, HttpError, FormValues>()

    const fields: FieldData[] = [
        {
            name: 'name',
            rules: [
                { required: true, message: t(`${i18nBase}.validate.name.required`) },
                { min: 1, max: 50, message: t(`${i18nBase}.validate.name.length`) },
                { pattern: /^[\w-_]+$/, message: t(`${i18nBase}.validate.name.pattern`) },
            ],
            field: <Input />,
        },
        {
            name: 'schedule',
            rules: [{ required: true, message: t(`${i18nBase}.validate.schedule.required`) }],
            field: (
                <Select
                    options={SCHEDULE_EVENT_TYPES.map((scheduleType) => ({
                        value: scheduleType,
                        label: t(`notificationEvents.eventTypes.${scheduleType}`),
                    }))}
                />
            ),
        },
        // {
        //     name: 'dateRange',
        //     rules: [{ required: true, message: t(`${i18nBase}.validate.dataRange.required`) }],
        //     field: <RangePicker format={DATE_FORMAT} showTime={{ showSecond: false }} />,
        // },
        {
            name: 'isActive',
            valuePropName: 'checked',
            field: <Checkbox />,
        },
        {
            name: 'title',
            rules: [
                { required: true, message: '' },
                {
                    validator: async (_rule, value: LocaleValue) => {
                        if (
                            !value ||
                            typeof value !== 'object' ||
                            !Object.values(value).some((text) => !!text?.trim())
                        ) {
                            throw new Error(t(`${i18nBase}.validate.title.required`))
                        }
                    },
                },
            ],
            field: <LocaleTextField />,
        },
        {
            name: 'description',
            field: <LocaleTextArea />,
        },
    ]

    const onFinish = useCallback(
        (values: FormValues): void => {
            const title: LocaleValue = values.title
                ? typeof values.title === 'string'
                    ? JSON.parse(values.title)
                    : values.title
                : {}
            const description: LocaleValue = values.description
                ? typeof values.description === 'string'
                    ? JSON.parse(values.description)
                    : values.description
                : {}

            formProps.onFinish?.({
                name: values.name,
                schedule: JSON.stringify({ 'type': values.schedule }),
                isActive: values.isActive,
                title: JSON.stringify(title),
                description: JSON.stringify(description),
                // dateStart: values.dateRange?.[0]?.utcOffset(0).format(ISO_DATE_FORMAT_UTC) ?? '',
                // dateEnd: values.dateRange?.[1]?.utcOffset(0).format(ISO_DATE_FORMAT_UTC) ?? '',
            })
        },
        [formProps]
    )

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} onFinish={onFinish} layout="vertical">
                {fields.map(({ name, field, ...props }) => {
                    return (
                        <Form.Item {...props} key={name} label={t(`${i18nBase}.fields.${name}`)} name={name}>
                            {field}
                        </Form.Item>
                    )
                })}
            </Form>
        </Create>
    )
}
