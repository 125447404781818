import { FC } from 'react'
import {
    IResourceComponentsProps,
    useNavigation,
} from '@pankod/refine-core'
import {
    List,
    Table,
    TextField,
    DateField,
    useTable,
    BooleanField,
} from '@pankod/refine-antd'
import { IBible } from 'interfaces'
import { DATE_FORMAT } from '../../constants'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'

export const BiblesList: FC<IResourceComponentsProps> = () => {
    const { t, i18n } = useTranslation()
    const currentLanguage = i18n.language
    const navigation = useNavigation()


    const { tableProps } = useTable<IBible>({
        initialSorter: [
            {
                field: 'id',
                order: 'desc',
            },
        ],
    })

    const handleBibleOpen = (record: IBible) => {
        navigation.push(`/bibles/show/${record.id}`)
    }

    return (
        <List>
            <Table
                {...tableProps}
                rowClassName={styles.tableRow}
                rowKey="id"
                onRow={(record, rowIndex) => ({
                    onClick: () => handleBibleOpen(record),
                })}
            >
                <Table.Column
                    dataIndex="language"
                    key="language"
                    title={t('bibles.fields.language')}
                    render={(value) => (
                        <TextField value={t(`bibles.languages.${value}`)} />
                    )}
                />
                <Table.Column
                    dataIndex="translation"
                    key="translation"
                    title={t('bibles.fields.translation')}
                    render={(value) => (
                        <TextField value={value} />
                    )}
                />
                <Table.Column
                    dataIndex="title"
                    key="title"
                    title={t('bibles.fields.title')}
                    render={(value) => (
                        <TextField value={value[currentLanguage]} />
                    )}
                />
                <Table.Column
                    dataIndex="isPublished"
                    title={t('bibles.fields.isPublished')}
                    render={(value) => {
                        return <BooleanField value={value} />
                    }}
                />
                <Table.Column
                    dataIndex="isSearchAvailable"
                    title={t('bibles.fields.isSearchAvailable')}
                    render={(value) => {
                        return <BooleanField value={value} />
                    }}
                />
                <Table.Column
                    dataIndex="isVoiceSearchAvailable"
                    title={t('bibles.fields.isVoiceSearchAvailable')}
                    render={(value) => {
                        return <BooleanField value={value} />
                    }}
                />
                <Table.Column
                    dataIndex="createdAt"
                    key="createdAt"
                    title={t('users.fields.createdAt')}
                    render={(value) =>
                        value ? (
                            <DateField
                                value={value ? value : ''}
                                format={DATE_FORMAT}
                            />
                        ) : (
                            '-'
                        )
                    }
                />
                <Table.Column
                    dataIndex="updatedAt"
                    key="updatedAt"
                    title={t('users.fields.updatedAt')}
                    render={(value) =>
                        value ? (
                            <DateField
                                value={value ? value : ''}
                                format={DATE_FORMAT}
                            />
                        ) : (
                            '-'
                        )
                    }
                />
            </Table>
        </List>
    )
}
