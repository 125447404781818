import { ChangeEvent, FC, useRef, useState } from 'react'
import {
    Button,
    Col,
    Icons,
    Input,
    notification,
    Result,
    Row,
    Spin,
    Typography,
} from '@pankod/refine-antd'
import { Link } from 'react-router-dom'
import { IFavoriteVerse, ISearchResult, IVerse } from '../../../interfaces'
import { useLocalStorage, useVerseCopy } from '../../../hooks'
import { useGetIdentity, useGetLocale, useTranslate } from '@pankod/refine-core'
import { AxiosResponse } from 'axios'
import axiosInstance from '../../../setup'
import { API_ROOT } from '../../../constants'
import styles from './style.module.css'

const { Title } = Typography
const { Search } = Input

interface VersesSearchFormProps {
    bibleId: string
}

const anyWindow = window as any
const SpeechRecognition =
    anyWindow.SpeechRecognition || anyWindow.webkitSpeechRecognition

const LocaleMap: { [key: string]: string } = {
    ru: 'ru-RU',
    en: 'en-US',
}

const VersesSearchForm: FC<VersesSearchFormProps> = ({ bibleId }) => {
    const controller = useRef<AbortController | null>(null)

    const { data } = useGetIdentity()
    const t = useTranslate()
    const locale = useGetLocale()
    const { copyVerseToClipBoard } = useVerseCopy()

    const [storedSearchResults, setStoredSearchResults] = useLocalStorage<{
        searchResults: Array<string>
    }>(`${data.id}-search-result`, { searchResults: [] })
    const [storedVerses, setStoredVerses] = useLocalStorage<{
        verses: Array<IFavoriteVerse>
    }>(`${data.id}-verses`, { verses: [] })

    const [searchText, setSearchText] = useState<string>('')
    const [isSearchLoading, setLoading] = useState(false)
    const [isError, setError] = useState(false)
    const [isListening, setListening] = useState(false)
    const [searchResults, setSearchResults] = useState<
        undefined | Array<ISearchResult>
    >(undefined)

    const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value)
    }

    const onSearch = async (searchedText?: string) => {
        if (searchedText || searchText) {
            setSearchResults(undefined)
            setError(false)
            setLoading(true)
            try {
                controller.current = new AbortController()
                const res: AxiosResponse<Array<ISearchResult>> =
                    await axiosInstance.get(
                        `${API_ROOT}/bibles/${bibleId}/search`,
                        {
                            signal: controller.current.signal,
                            params: {
                                text: searchedText || searchText,
                                limit: 12,
                            },
                        }
                    )
                if (res.statusText !== 'OK') {
                    setError(true)
                    console.error(res.status, res.statusText)
                } else {
                    const results = res.data.slice(0, 12)
                    setSearchResults(results)
                }
            } catch (err: any) {
                console.error(err)
                if (err.code !== 'ERR_CANCELED') {
                    setError(true)
                }
            } finally {
                const storedText = searchedText || searchText
                if (!storedSearchResults.searchResults.includes(storedText)) {
                    const currentUserSearchResults = [
                        ...storedSearchResults.searchResults,
                        storedText,
                    ]
                    setStoredSearchResults({
                        searchResults: currentUserSearchResults,
                    })
                }
                setLoading(false)
            }
        }
    }

    const cancelSearch = () => {
        controller.current?.abort()
        setLoading(false)
    }

    const onFavoriteVerseChange = (verse: IFavoriteVerse) => {
        if (
            storedVerses.verses.find(
                (storedVerse: IVerse) => storedVerse.id === verse.id
            )
        ) {
            setStoredVerses({
                verses: storedVerses.verses.filter(
                    (storedVerse: IVerse) => storedVerse.id !== verse.id
                ),
            })
            notification.open({
                message: t('notifications.versesRemoveSuccess'),
                type: 'error',
            })
        } else {
            setStoredVerses({ verses: [...storedVerses.verses, verse] })
            notification.open({
                message: t('notifications.versesAddSuccess'),
                type: 'success',
            })
        }
    }

    const onRecentSearchClick = async (text: string) => {
        setSearchText(text)
        await onSearch(text)
    }

    const onClearRecentSearchResults = () => {
        setStoredSearchResults({ searchResults: [] })
    }

    const onRemoveRecentSearchResult = (text: string) => {
        setStoredSearchResults({
            searchResults: storedSearchResults.searchResults.filter(
                (searchResult: string) => searchResult !== text
            ),
        })
    }

    const onSpeakRecognize = () => {
        if (
            'SpeechRecognition' in window ||
            'webkitSpeechRecognition' in window
        ) {
            let recognition = new SpeechRecognition()
            const currentLocale = locale()

            if (currentLocale) {
                recognition.lang = LocaleMap[currentLocale]
            }

            recognition.onstart = () => {
                setListening(true)
            }

            recognition.onspeechend = () => {
                setListening(false)
                recognition.stop()
            }

            recognition.onresult = async (result: any) => {
                const recognizedResult = result.results[0][0].transcript
                setSearchText(recognizedResult)
                await onSearch(recognizedResult)
            }

            recognition.start()
        }
    }

    return (
        <>
            <Row style={{ marginBottom: '16px' }}>
                <Col xs={12} offset={2}>
                    <Title level={4}>{t('verses.search.title')}</Title>
                    <div style={{ display: 'flex' }}>
                        <Search
                            value={searchText}
                            size="large"
                            loading={isSearchLoading}
                            onChange={onSearchChange}
                            onSearch={onSearch}
                            enterButton
                            placeholder={t('verses.search.placeholder')}
                            style={{ marginBottom: '16px' }}
                        />
                        {('SpeechRecognition' in window ||
                            'webkitSpeechRecognition' in window) && (
                            <Button
                                style={{ marginLeft: '8px' }}
                                size="large"
                                onClick={onSpeakRecognize}
                                type="primary"
                                loading={isListening || isSearchLoading}
                                icon={<Icons.AudioOutlined />}
                            >
                                {isListening
                                    ? `${t('buttons.speak')}...`
                                    : undefined}
                            </Button>
                        )}
                    </div>
                    {isError && (
                        <div className={styles.placeholdersContainer}>
                            <Result
                                status="error"
                                title={t('verses.search.errorTitle')}
                                subTitle={t('pages.error.general')}
                            />
                        </div>
                    )}
                    {isSearchLoading && !isError && (
                        <div className={styles.placeholdersContainer}>
                            <Spin size="large" />
                            <Title level={5}>
                                {t('verses.search.search')}...
                            </Title>
                            <Button onClick={cancelSearch}>
                                {t('buttons.cancel')}
                            </Button>
                        </div>
                    )}
                    {searchResults && searchResults.length > 0 && (
                        <ul className={styles.searchResultsList}>
                            {searchResults.map((searchResult) => (
                                <li
                                    className={styles.searchResultsListItem}
                                    key={searchResult.id}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div>
                                            <Title
                                                level={5}
                                                style={{
                                                    margin: 0,
                                                }}
                                            >
                                                {searchResult.book.bookTitle} ({searchResult.book.name})
                                            </Title>
                                            <Typography
                                                className={
                                                    styles.searchResultsSubTitle
                                                }
                                            >
                                                {t('book.chapter')}:{' '}
                                                {searchResult.chapterNumber}
                                            </Typography>
                                            <Typography
                                                className={
                                                    styles.searchResultsSubTitle
                                                }
                                            >
                                                {t('book.verse')}:{' '}
                                                {searchResult.verseNumber}
                                            </Typography>
                                        </div>
                                        <div
                                            className={
                                                styles.searchResultsButtonContainer
                                            }
                                        >
                                            <Link
                                                to={`/bibles/show/${searchResult.book.bibleId}/book/${searchResult.book.id}?chapter=${searchResult.chapterNumber}&verseId=${searchResult.id}`}
                                            >
                                                <Icons.EyeFilled
                                                    style={{
                                                        marginRight: '8px',
                                                    }}
                                                />
                                                {t('buttons.show')}
                                            </Link>
                                            <Button
                                                onClick={() =>
                                                    copyVerseToClipBoard(
                                                        searchResult
                                                    )
                                                }
                                                style={{ padding: 0 }}
                                                size="small"
                                                type="link"
                                            >
                                                <Icons.CopyFilled />
                                                {t('buttons.copy')}
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    onFavoriteVerseChange(
                                                        searchResult
                                                    )
                                                }
                                                style={{ padding: 0 }}
                                                size="small"
                                                type="link"
                                            >
                                                {storedVerses.verses.find(
                                                    (sV: IVerse) =>
                                                        sV.id ===
                                                        searchResult.id
                                                ) ? (
                                                    <Icons.StarFilled />
                                                ) : (
                                                    <Icons.StarOutlined />
                                                )}
                                                {t('buttons.favorite')}
                                            </Button>
                                        </div>
                                    </div>
                                    <Typography style={{ marginTop: '8px' }}>
                                        {searchResult.text}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    )}
                    {searchResults && searchResults.length === 0 && (
                        <div className={styles.placeholdersContainer}>
                            {t('verses.search.noResult')}
                        </div>
                    )}
                </Col>
                {storedSearchResults.searchResults.filter(
                    (sR: string) => sR.length > 0
                ).length > 0 && (
                    <Col xs={8} offset={2}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Title level={4}>
                                {t('verses.search.recentSearch')}
                            </Title>
                            <Button
                                onClick={onClearRecentSearchResults}
                                size="small"
                            >
                                {t('buttons.clear')}
                            </Button>
                        </div>
                        <ul
                            style={{ listStyle: 'none', margin: 0, padding: 0 }}
                        >
                            {storedSearchResults.searchResults
                                .filter(
                                    (searchResult: string) =>
                                        searchResult.length > 0
                                )
                                .map((searchResult: string, idx: number) => (
                                    <li
                                        key={idx}
                                        style={{
                                            marginBottom: '8px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            borderBottom: '1px solid #d9d9d9',
                                            paddingBottom: '4px',
                                        }}
                                    >
                                        <Button
                                            style={{ overflow: 'hidden' }}
                                            size="small"
                                            onClick={async () =>
                                                await onRecentSearchClick(
                                                    searchResult
                                                )
                                            }
                                            type="text"
                                        >
                                            <Icons.SearchOutlined />{' '}
                                            {searchResult}
                                        </Button>
                                        <Button
                                            size="small"
                                            type="text"
                                            onClick={() =>
                                                onRemoveRecentSearchResult(
                                                    searchResult
                                                )
                                            }
                                            icon={<Icons.CloseOutlined />}
                                        />
                                    </li>
                                ))}
                        </ul>
                    </Col>
                )}
            </Row>
        </>
    )
}

export default VersesSearchForm
