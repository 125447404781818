import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { CrudFilters, IResourceComponentsProps, usePermissions } from '@pankod/refine-core'
import { List, useTable, useModal, Modal } from '@pankod/refine-antd'
import { useTranslation } from 'react-i18next'
import { Calendar, DateLocalizer, dayjsLocalizer, Event, Messages, View } from 'react-big-calendar'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/en'
import 'dayjs/locale/ru'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { ISO_DATE_FORMAT_UTC } from '../../constants'

import { CalendarEventShow } from '../calendar-events'

import getLocaleText from '../../utils/getLocaleText'
import type { ICalendarEvent, Language } from '../../interfaces'
import { Roles } from '../../interfaces/roles'

dayjs.extend(utc)

type CalendarDateRange = Date[] | { start: Date; end: Date }

const defaultView: View = 'month'
const defaultLocalizer = dayjsLocalizer(dayjs)

// prettier-ignore
const messageKeys: (keyof Messages)[] = [
    'date', 'time', 'event', 'allDay', 'week', 'work_week', 'day', 'month',
    'previous', 'next', 'yesterday', 'tomorrow', 'today', 'agenda', 'noEventsInRange',
]

const getFilters = (dateStart: Dayjs, dateEnd: Dayjs): CrudFilters => {
    return [
        { field: 'isActive', operator: 'eq', value: true },
        {
            field: 'dateEnd',
            operator: 'gte',
            value: dateStart.utcOffset(0).format(ISO_DATE_FORMAT_UTC),
        },
        {
            field: 'dateStart',
            operator: 'lte',
            value: dateEnd.utcOffset(0).format(ISO_DATE_FORMAT_UTC),
        },
    ]
}

export const CalendarList: FunctionComponent<IResourceComponentsProps> = () => {
    const { t, i18n } = useTranslation()
    const { data: permissions } = usePermissions()
    const [events, setEvents] = useState<Event[]>([])
    const [localizer, setLocalizer] = useState<DateLocalizer>(defaultLocalizer)
    const [messages, setMessages] = useState<Messages | undefined>()
    const [selectedEvent, setSelectedEvent] = useState<ICalendarEvent | undefined>(undefined)
    const { tableQueryResult, setFilters } = useTable<ICalendarEvent>({
        resource: 'calendarEvents',
        defaultSetFilterBehavior: 'replace',
        // This breaks Refine's/ReactQuery's fetching
        // hasPagination: false,
        // syncWithLocation: true,
        initialFilter: getFilters(dayjs().startOf(defaultView), dayjs().endOf(defaultView)),
        initialPageSize: 100,
    })
    const { modalProps: eventModalProps, show: showEventModal, close: closeEventModal } = useModal()

    const currentLanguage: Language = i18n.language as Language
    const items = tableQueryResult.data?.data
    const isAdmin = !!permissions?.includes(Roles.ADMIN)

    useEffect(() => {
        dayjs.locale(currentLanguage)
        setLocalizer(dayjsLocalizer(dayjs))
        setMessages(messageKeys.reduce((result, key) => ({ ...result, [key]: t(`calendar.messages.${key}`) }), {}))
    }, [currentLanguage, t])

    useEffect(() => {
        setEvents(
            items?.map((event) => ({
                title: getLocaleText(event.title, currentLanguage),
                start: dayjs(event.dateStart).toDate(),
                end: dayjs(event.dateEnd).toDate(),
                resource: event,
            })) ?? []
        )
    }, [items, currentLanguage])

    /**
     * @todo `setFilters` is changing every render no matter what so this callback is also changing every render.
     * This makes it impossible to move `setFilters` into `useEffect` hook to update filters on another value change.
     * If we need that should move this code into a separate function and call it when needed.
     **/
    const onRangeChange = useCallback(
        (range: CalendarDateRange): void | undefined => {
            let start: Dayjs | undefined = undefined
            let end: Dayjs | undefined = undefined
            if (Array.isArray(range)) {
                start = dayjs(range[0])
                end = dayjs(range[range.length - 1])
            } else {
                start = dayjs(range.start)
                end = dayjs(range.end)
            }
            setFilters(getFilters((start ?? dayjs()).startOf('day'), (end ?? dayjs()).endOf('day')))
        },
        [setFilters]
    )

    const onSelectEvent = useCallback(
        (event: Event) => {
            setSelectedEvent(event.resource)
            showEventModal()
        },
        [showEventModal]
    )

    const onEventModalClose = useCallback(() => {
        setSelectedEvent(undefined)
        closeEventModal()
    }, [closeEventModal])

    return (
        <List canCreate={isAdmin} resource="calendarEvents">
            <Calendar
                defaultView={defaultView}
                views={['month', 'week', 'day']}
                events={events}
                localizer={localizer}
                messages={messages}
                // 64px  - Main header
                // 24px, 24px - Main top and bottom padding
                // 12px, 16px - List top and bottom padding
                // 40px - List header height
                // 12px - List content top padding
                // 64 + 24 + 24 + 12 + 16 + 40 + 12 = 192
                style={{ height: 'calc(100vh - 192px)' }}
                onRangeChange={onRangeChange}
                onSelectEvent={onSelectEvent}
            />

            <Modal
                {...eventModalProps}
                onOk={onEventModalClose}
                width="640px"
                footer={null}
                style={{ maxHeight: 'calc(100vh - 20%)', overflow: 'auto', paddingBottom: 0 }}
            >
                <CalendarEventShow initialData={{ id: selectedEvent?.id }} />
            </Modal>
        </List>
    )
}
