import { Authenticated, Refine } from '@pankod/refine-core'
import { notificationProvider, ReadyPage, ErrorComponent, Icons, ConfigProvider } from '@pankod/refine-antd'
import enUS from 'antd/lib/locale/en_US'
import ruRU from 'antd/lib/locale/ru_RU'
import deDE from 'antd/lib/locale/de_DE'
import '@pankod/refine-antd/dist/styles.min.css'
import routerProvider from '@pankod/refine-react-router-v6'
import dataProvider from '@pankod/refine-nestjsx-crud'
import { authProvider } from './authProvider'
import { UsersList, UsersCreate, UsersEdit, UsersShow } from 'pages/users'
import { BiblesList, BiblesShow } from 'pages/bibles'
import { CalendarEventCreate, CalendarEventList, CalendarEventEdit, CalendarEventShow } from 'pages/calendar-events'
import { CalendarList } from 'pages/calendar'
import { SettingsList } from 'pages/settings'
import { Title, Header, Sider, Footer, Layout, OffLayoutArea } from 'components/layout'
import { useTranslation } from 'react-i18next'
import { Login } from 'pages/login'
import * as constants from './constants'
import axiosInstance from './setup'
import BookView from './components/bibles/BookView'
import FavoritesList from './pages/favorites/list'

import 'styles/antd.less'
import { NotificationEventCreate, NotificationEventList } from 'pages/notification-events'

const LOCALE_MAP: { [key: string]: any } = {
    ru: ruRU,
    en: enUS,
    de: deDE,
}

function App() {
    const { t, i18n } = useTranslation()
    const currentLanguage = i18n.language

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    }

    return (
        <ConfigProvider locale={LOCALE_MAP[currentLanguage]}>
            <Refine
                options={{
                    disableTelemetry: true,
                    warnWhenUnsavedChanges: true,
                }}
                notificationProvider={notificationProvider}
                ReadyPage={ReadyPage}
                catchAll={<ErrorComponent />}
                routerProvider={{
                    ...routerProvider,
                    routes: [
                        {
                            element: (
                                <Authenticated>
                                    <BookView />
                                </Authenticated>
                            ),
                            path: '/bibles/show/:bibleId/book/:bookId',
                            layout: true,
                        },
                        {
                            element: (
                                <Authenticated>
                                    <FavoritesList />
                                </Authenticated>
                            ),
                            path: '/favorites',
                            layout: true,
                        },
                    ],
                }}
                dataProvider={dataProvider(`${constants.API_ROOT}`, axiosInstance)}
                authProvider={authProvider}
                accessControlProvider={{
                    can: async ({ resource, action }) => {
                        let can = true

                        const profile = localStorage.getItem('profile')
                        if (!profile) {
                            throw new Error('Error getting profile from local storage!')
                        }
                        const role = JSON.parse(profile).role

                        if (role !== 'admin') {
                            if (resource === 'users' || resource === 'settings') {
                                //action === "list"
                                can = false
                            }
                        }

                        return Promise.resolve({ can })
                    },
                }}
                LoginPage={Login}
                // DashboardPage={DashboardPage}
                resources={[
                    {
                        name: 'bibles',
                        icon: <Icons.BookOutlined />,
                        list: BiblesList,
                        show: BiblesShow,
                    },
                    {
                        name: 'users',
                        icon: <Icons.UserOutlined />,
                        list: UsersList,
                        create: UsersCreate,
                        edit: UsersEdit,
                        show: UsersShow,
                    },
                    {
                        name: 'calendarEvents',
                        icon: <Icons.CalendarOutlined />,
                        create: CalendarEventCreate,
                        list: CalendarEventList,
                        edit: CalendarEventEdit,
                        show: CalendarEventShow,
                    },
                    {
                        name: 'calendar',
                        icon: <Icons.CalendarTwoTone />,
                        list: CalendarList,
                    },
                    {
                        name: 'notificationEvents',
                        icon: <Icons.NotificationOutlined />,
                        create: NotificationEventCreate,
                        list: NotificationEventList,
                        // edit: NotificationEventEdit,
                        // show: NotificationEventShow,
                    },
                    {
                        name: 'settings',
                        icon: <Icons.SettingOutlined />,
                        list: SettingsList,
                    },
                ]}
                Title={Title}
                Header={Header}
                Sider={Sider}
                Footer={Footer}
                Layout={Layout}
                OffLayoutArea={OffLayoutArea}
                i18nProvider={i18nProvider}
            />
        </ConfigProvider>
    )
}

export default App
