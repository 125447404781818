import { useState } from 'react'
import { notification } from '@pankod/refine-antd'
import { IFavoriteVerse } from './interfaces'
import { useTranslate } from '@pankod/refine-core'
import lodash from 'lodash'
import { cleanVerseText } from './utils'

export const useLocalStorage = <T>(key: string, initialValue: T) => {
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === 'undefined') {
            return initialValue
        }
        try {
            const item = window.localStorage.getItem(key)
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            console.error(error)
            return initialValue
        }
    })

    const setValue = (value: T) => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value
            setStoredValue(valueToStore)
            if (typeof window !== 'undefined') {
                window.localStorage.setItem(key, JSON.stringify(valueToStore))
            }
        } catch (error) {
            console.error(error)
        }
    }
    return [storedValue, setValue]
}

export const useVerseCopy = () => {
    const t = useTranslate()

    const multipleVerseCopyToClipBoard = async (
        searchResults: Array<IFavoriteVerse>
    ) => {
        try {
            const output: Array<Array<Array<IFavoriteVerse>>> = []
            let outputText = ''
            // 1. Group verses by book number
            const groupedByBookNumber: any = lodash.groupBy(
                searchResults,
                'bookNumber'
            )

            // 2. Group result by chapters
            for (let bookNumber in groupedByBookNumber) {
                const groupedByChapter = lodash.groupBy(
                    groupedByBookNumber[bookNumber],
                    'chapterNumber'
                )

                for (let chapterNumber in groupedByChapter) {
                    // 3. In every chapter makes groups of verses by sequence of verse number
                    let groupedBySequence: any = []
                    let sequence: any = []
                    groupedByChapter[chapterNumber]
                        .sort(
                            (verseA, verseB) =>
                                verseA.verseNumber - verseB.verseNumber
                        )
                        .forEach((verse, index) => {
                            if (groupedByChapter[chapterNumber].length === 1) {
                                groupedBySequence.push([verse])
                            } else {
                                if (index === 0) {
                                    sequence.push(verse)
                                } else {
                                    if (
                                        verse.verseNumber -
                                            groupedByChapter[chapterNumber][
                                                index - 1
                                            ].verseNumber ===
                                        1
                                    ) {
                                        sequence.push(verse)
                                        if (
                                            index ===
                                            groupedByChapter[chapterNumber]
                                                .length -
                                                1
                                        ) {
                                            groupedBySequence.push(sequence)
                                        }
                                    } else {
                                        groupedBySequence.push(sequence)
                                        sequence = []
                                        sequence.push(verse)
                                        if (
                                            index ===
                                            groupedByChapter[chapterNumber]
                                                .length -
                                                1
                                        ) {
                                            groupedBySequence.push(sequence)
                                        }
                                    }
                                }
                            }
                        })
                    output.push(groupedBySequence)
                }
            }

            // 4. Convert array of verse to string
            output.forEach((chapterGroup) =>
                chapterGroup.forEach((verseSequenceGroup) => {
                    // If there is a single verse we don't need to add number of verse to text and display header as range of verse numbers
                    if (verseSequenceGroup.length === 1) {
                        const singleVerse = verseSequenceGroup[0]
                        outputText += `${singleVerse.book.bookTitle} ${singleVerse.chapterNumber}:${singleVerse.verseNumber}`
                        outputText += '\n'
                        outputText += cleanVerseText(singleVerse.text)
                        outputText += '\n'
                    } else {
                        // If there is a group of verse we need to add verse number range to header and add number of verse to every verse text
                        const firstVerse = verseSequenceGroup[0]
                        const lastVerse =
                            verseSequenceGroup[verseSequenceGroup.length - 1]
                        outputText += `${firstVerse.book.bookTitle} ${firstVerse.chapterNumber}:${firstVerse.verseNumber}-${lastVerse.verseNumber}`
                        outputText += '\n'
                        verseSequenceGroup.forEach((verse) => {
                            outputText += `${
                                verse.verseNumber
                            } ${cleanVerseText(verse.text)}`
                            outputText += '\n'
                        })
                    }
                })
            )

            await navigator.clipboard.writeText(outputText)

            notification.open({
                message: t('notifications.versesCopySuccess'),
                type: 'success',
            })
        } catch (e) {
            console.error(e)
            notification.open({
                message: t('notifications.versesCopyError'),
                type: 'error',
            })
        }
    }

    const copyVerseToClipBoard = async (searchResult: IFavoriteVerse) => {
        try {
            const additionalInfo = `${searchResult.book.name} ${searchResult.chapterNumber}:${searchResult.verseNumber}`
            await navigator.clipboard.writeText(
                additionalInfo + '\n' + searchResult.text
            )
            notification.open({
                message: t('notifications.versesCopySuccess'),
                type: 'success',
            })
        } catch (e) {
            console.error(e)
            notification.open({
                message: t('notifications.versesCopyError'),
                type: 'error',
            })
        }
    }

    return { copyVerseToClipBoard, multipleVerseCopyToClipBoard }
}
