import { Fragment, type FunctionComponent } from 'react'
import { type IResourceComponentsProps, useShow, usePermissions } from '@pankod/refine-core'
import { useTranslation } from 'react-i18next'
import { Show, Typography, MarkdownField, DateField, BooleanField } from '@pankod/refine-antd'

import { DATE_FORMAT } from '../../constants'

import { Roles } from '../../interfaces/roles'
import type { ICalendarEvent, Language, LocaleValue } from '../../interfaces'

const { Title, Text } = Typography

export const CalendarEventShow: FunctionComponent<IResourceComponentsProps> = ({ initialData }) => {
    const { t, i18n } = useTranslation()
    const { data: permissions } = usePermissions()
    const {
        queryResult: { data, isLoading },
    } = useShow<ICalendarEvent>({ resource: 'calendarEvents', id: initialData?.id })

    const calendarEvent = data?.data
    const isAdmin = !!permissions?.includes(Roles.ADMIN)
    const languages = i18n.languages ?? []

    return (
        <Show canEdit={isAdmin} resource="calendarEvents" recordItemId={initialData?.id} isLoading={isLoading}>
            {!!calendarEvent && (
                <Fragment>
                    <Title level={4}>{t('calendarEvents.item.title')}</Title>
                    {languages.map((language) => {
                        const title = (calendarEvent.title as LocaleValue)?.[language as Language]
                        if (title) {
                            return (
                                <Fragment key={language}>
                                    <Title level={5}>{t(`languages.${language}`)}</Title>
                                    <Text>{title}</Text>
                                </Fragment>
                            )
                        }
                    })}

                    <Title level={4}>{t('calendarEvents.item.eventType')}</Title>
                    <Text>{t(`calendarEvents.eventTypes.${calendarEvent.eventType}`, undefined, '-')}</Text>

                    <Title level={4}>{t('calendarEvents.item.dateStart')}</Title>
                    <Text>
                        <DateField value={calendarEvent.dateStart} format={DATE_FORMAT} />
                    </Text>

                    <Title level={4}>{t('calendarEvents.item.dateEnd')}</Title>
                    <Text>
                        <DateField value={calendarEvent.dateEnd} format={DATE_FORMAT} />
                    </Text>

                    <Title level={4}>{t('calendarEvents.item.isActive')}</Title>
                    <Text>
                        <BooleanField value={calendarEvent.isActive} />
                    </Text>

                    <Title level={4}>{t('calendarEvents.item.description')}</Title>
                    {languages.map((language) => {
                        const description = (calendarEvent.description as LocaleValue)?.[language as Language]
                        if (description) {
                            return (
                                <Fragment key={language}>
                                    <Title level={5}>{t(`languages.${language}`)}</Title>
                                    <Text>
                                        <MarkdownField value={description} />
                                    </Text>
                                </Fragment>
                            )
                        }
                    })}
                </Fragment>
            )}
        </Show>
    )
}
