import { useState, FC } from 'react'

import {
    useTranslate,
    useLogout,
    CanAccess,
    useTitle,
    ITreeMenu,
    useRouterContext,
    useMenu,
    useNavigation,
} from '@pankod/refine-core'
import { AntdLayout, Menu, Grid, Icons } from '@pankod/refine-antd'
import { useLocation } from 'react-router-dom'
import styles from './styles.module.css'

const { UnorderedListOutlined, LogoutOutlined } = Icons

export const Sider: FC = () => {
    const { pathname } = useLocation()
    const navigation = useNavigation()
    const [collapsed, setCollapsed] = useState<boolean>(false)

    const { mutate: logout } = useLogout()

    const { Link } = useRouterContext()
    const Title = useTitle()
    const { SubMenu } = Menu

    const translate = useTranslate()
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu()
    const breakpoint = Grid.useBreakpoint()

    const isMobile = !breakpoint.lg

    const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
        return tree.map((item: ITreeMenu) => {
            const { icon, label, route, name, children, parentName } = item

            if (children.length > 0) {
                return (
                    <SubMenu
                        key={name}
                        icon={icon ?? <UnorderedListOutlined />}
                        title={label}
                    >
                        {renderTreeView(children, selectedKey)}
                    </SubMenu>
                )
            }
            const isSelected = route === selectedKey
            const isRoute = !(parentName !== undefined && children.length === 0)
            return (
                <CanAccess
                    key={route}
                    resource={name.toLowerCase()}
                    action="list"
                >
                    <Menu.Item
                        key={selectedKey}
                        style={{
                            fontWeight: isSelected ? 'bold' : 'normal',
                        }}
                        icon={icon ?? (isRoute && <UnorderedListOutlined />)}
                    >
                        <Link to={route}>{label}</Link>
                        {!collapsed && isSelected && (
                            <div className="ant-menu-tree-arrow" />
                        )}
                    </Menu.Item>
                </CanAccess>
            )
        })
    }

    return (
        <AntdLayout.Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
            collapsedWidth={isMobile ? 0 : 80}
            breakpoint="lg"
            className={
                isMobile ? styles.antLayoutSiderMobile : styles.antLayoutSider
            }
        >
            {Title && <Title collapsed={collapsed} />}
            <Menu
                selectedKeys={[selectedKey]}
                defaultOpenKeys={defaultOpenKeys}
                mode="inline"
                onClick={() => {
                    if (!breakpoint.lg) {
                        setCollapsed(true)
                    }
                }}
                style={{ borderRight: 0 }}
            >
                {renderTreeView(menuItems, selectedKey)}
                <Menu.Item
                    style={{
                        fontWeight:
                            pathname === '/favorites' ? 'bold' : 'normal',
                        background:
                            pathname === '/favorites' ? '#179BE5' : undefined,
                        borderRight:
                            pathname === '/favorites'
                                ? '3px solid white'
                                : undefined,
                    }}
                    key="favorites"
                    icon={<Icons.StarFilled />}
                    onClick={() => navigation.push('/favorites')}
                >
                    {translate('favorites.title')}
                    {!collapsed && pathname === '/favorites' && (
                        <div className="ant-menu-tree-arrow" />
                    )}
                </Menu.Item>
                <Menu.Item
                    key="logout"
                    onClick={() => logout()}
                    icon={<LogoutOutlined />}
                >
                    {translate('buttons.logout', 'Logout')}
                </Menu.Item>
            </Menu>
        </AntdLayout.Sider>
    )
}
