import { FC, useMemo, useState } from 'react'
import {
    IResourceComponentsProps,
    useCustom,
    useNavigation,
    useResource,
    useShow,
    useTranslate,
} from '@pankod/refine-core'
import { Col, Row, Show, Table, Typography, Radio, TextField } from '@pankod/refine-antd'
import { IBible, IBibleBooksResponse } from '../../interfaces'
import { API_ROOT } from '../../constants'
import VersesSearchForm from '../../components/verses/SearchForm'
import styles from './styles.module.css'

const { Title, Text } = Typography

export const BiblesShow: FC<IResourceComponentsProps> = () => {
    const { id } = useResource()
    const t = useTranslate()
    const navigate = useNavigation()

    const [currentTestament, setCurrentTestament] = useState<'old' | 'new'>(
        'old'
    )

    const { queryResult } = useShow<IBible>()
    const { data, isLoading: isBibleLoading } = queryResult
    const record = data?.data

    const { data: bibleBooks, isLoading } = useCustom<
        Array<IBibleBooksResponse>
    >({
        url: `${API_ROOT}/bibles/${id}/books`,
        method: 'get',
    })

    const handleBookOpen = (record: IBibleBooksResponse) => {
        navigate.push(`/bibles/show/${id}/book/${record.id}`)
    }

    const handleTestamentChange = (testamentType: 'new' | 'old') => {
        setCurrentTestament(testamentType)
    }

    const displayedBooks = useMemo(() => {
        switch (currentTestament) {
            case 'new': {
                return bibleBooks?.data
                    .filter((book) => book.division === 1)
                    .sort((a, b) => a.bookNumber - b.bookNumber)
            }
            case 'old': {
                return bibleBooks?.data
                    .filter((book) => book.division === 0)
                    .sort((a, b) => a.bookNumber - b.bookNumber)
            }
            default: {
                return bibleBooks?.data.sort(
                    (a, b) => a.bookNumber - b.bookNumber
                )
            }
        }
    }, [currentTestament, bibleBooks])

    return (
        <Show isLoading={isBibleLoading}>
            <Row>
                <Col lg={6} xs={6}>
                    <Row>
                        <Title level={4}>{t('bibles.fields.books')}</Title>
                    </Row>
                    <Row>
                        <Radio.Group
                            style={{ marginBottom: '16px' }}
                            buttonStyle="solid"
                            optionType="button"
                            options={[
                                {
                                    label: t('book.testaments.old'),
                                    value: 'old',
                                },
                                {
                                    label: t('book.testaments.new'),
                                    value: 'new',
                                },
                            ]}
                            onChange={(e) =>
                                handleTestamentChange(e.target.value)
                            }
                            value={currentTestament}
                        />
                    </Row>
                    <Row>
                        <Table
                            loading={isLoading}
                            size="small"
                            pagination={{
                                showSizeChanger: false,
                                position: ['bottomCenter'],
                            }}
                            dataSource={displayedBooks}
                            rowClassName={styles.tableRow}
                            onRow={(record) => ({
                                onClick: () => handleBookOpen(record),
                            })}
                        >
                            <Table.Column
                                align="center"
                                dataIndex="bookNumber"
                                key="bookNumber"
                                title={t('bibles.fields.bookNumber')}
                            />
                            <Table.Column
                                dataIndex="name"
                                key="name"
                                title={t('bibles.fields.name')}
                            />
                            <Table.Column
                                dataIndex="bookTitle"
                                key="bookTitle"
                                title={t('bibles.fields.bookTitle')}
                            />
                            <Table.Column
                                dataIndex="shortName"
                                key="shortName"
                                title={t('bibles.fields.shortName')}
                                render={(value) => (
                                    <TextField value={`${value?.map((value: string) => value).join(', ')}`} />
                                )}
                            />
                        </Table>
                    </Row>
                    <Row>
                        <Title level={4}>
                            {t('bibles.fields.description')}
                        </Title>
                    </Row>
                    <Row>
                        {Object.entries(record?.description ?? {}).map(
                            ([key, value]) => (
                                <>
                                    <Title level={5}>
                                        {t(`bibles.languages.${key}`)}
                                    </Title>
                                    <Text>{value}</Text>
                                </>
                            )
                        )}
                    </Row>
                </Col>
                <Col
                    xs={18}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <VersesSearchForm bibleId={id} />
                </Col>
            </Row>
        </Show>
    )
}
