import { FunctionComponent, type ReactNode, useCallback } from 'react'
import { useTranslate, IResourceComponentsProps, type HttpError, usePermissions } from '@pankod/refine-core'
import { Edit, Form, Input, DatePicker, Select, Checkbox, useForm } from '@pankod/refine-antd'
import { type FormItemProps } from 'antd/lib/form/FormItem'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'

import 'react-mde/lib/styles/css/react-mde-all.css'

import { ISO_DATE_FORMAT_UTC, DATE_FORMAT, CALENDAR_EVENT_TYPES } from '../../constants'

import LocaleTextField from '../../components/common/LocaleTextField'
import LocaleTextArea from '../../components/common/LocaleTextArea'

import type { ICalendarEvent, LocaleValue } from '../../interfaces'
import { Roles } from '../../interfaces/roles'

dayjs.extend(utc)

const { RangePicker } = DatePicker

const i18nBase = 'calendarEvents.form'

type FormValues = Omit<ICalendarEvent, 'createdAt' | 'updatedAt' | 'deletedAt'> & {
    dateRange?: [Dayjs, Dayjs]
    title?: ICalendarEvent['title']
    description?: ICalendarEvent['title']
}

type FieldData = FormItemProps<FormValues> & {
    name: keyof FormValues
    field: ReactNode
}

export const CalendarEventEdit: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate()
    const { data: permissions } = usePermissions()
    const { formProps, saveButtonProps } = useForm<FormValues, HttpError, FormValues>()

    const isAdmin = !!permissions?.includes(Roles.ADMIN)

    const fields: FieldData[] = [
        { name: 'id', rules: [{ required: true }], field: <Input />, style: { display: 'none' } },
        {
            name: 'name',
            rules: [
                { required: true, message: t(`${i18nBase}.validate.name.required`) },
                { min: 1, max: 50, message: t(`${i18nBase}.validate.name.length`) },
                { pattern: /^[\w-_]+$/, message: t(`${i18nBase}.validate.name.pattern`) },
            ],
            field: <Input />,
        },
        {
            name: 'eventType',
            rules: [{ required: true, message: t(`${i18nBase}.validate.eventType.required`) }],
            field: (
                <Select
                    options={CALENDAR_EVENT_TYPES.map((eventType) => ({
                        value: eventType,
                        label: t(`calendarEvents.eventTypes.${eventType}`),
                    }))}
                />
            ),
        },
        {
            name: 'dateRange',
            rules: [{ required: true, message: t(`${i18nBase}.validate.dataRange.required`) }],
            field: <RangePicker format={DATE_FORMAT} showTime={{ showSecond: false }} />,
        },
        {
            name: 'isActive',
            valuePropName: 'checked',
            field: <Checkbox />,
        },
        {
            name: 'title',
            rules: [
                { required: true, message: '' },
                {
                    validator: async (_rule, value?: LocaleValue) => {
                        if (
                            !value ||
                            typeof value !== 'object' ||
                            !Object.values(value).some((text) => !!text?.trim())
                        ) {
                            throw new Error(t(`${i18nBase}.validate.title.required`))
                        }
                    },
                },
            ],
            field: <LocaleTextField />,
        },
        {
            name: 'description',
            field: <LocaleTextArea />,
        },
    ]

    const onFinish = useCallback(
        (values: FormValues): void => {
            const title: LocaleValue = values.title
                ? typeof values.title === 'string'
                    ? JSON.parse(values.title)
                    : values.title
                : {}
            const description: LocaleValue = values.description
                ? typeof values.description === 'string'
                    ? JSON.parse(values.description)
                    : values.description
                : {}

            formProps.onFinish?.({
                id: values.id,
                name: values.name,
                eventType: values.eventType,
                isActive: values.isActive,
                title: JSON.stringify(title),
                description: JSON.stringify(description),
                dateStart: values.dateRange?.[0]?.utcOffset(0).format(ISO_DATE_FORMAT_UTC) ?? '',
                dateEnd: values.dateRange?.[1]?.utcOffset(0).format(ISO_DATE_FORMAT_UTC) ?? '',
            })
        },
        [formProps]
    )

    return (
        <Edit saveButtonProps={saveButtonProps} canDelete={isAdmin}>
            <Form
                {...formProps}
                initialValues={{
                    ...(formProps.initialValues ?? {}),
                    dateRange: formProps.initialValues
                        ? [dayjs(formProps.initialValues.dateStart), dayjs(formProps.initialValues.dateEnd)]
                        : undefined,
                }}
                onFinish={onFinish}
                layout="vertical"
            >
                {fields.map(({ name, field, ...props }) => {
                    return (
                        <Form.Item {...props} key={name} label={t(`${i18nBase}.fields.${name}`)} name={name}>
                            {field}
                        </Form.Item>
                    )
                })}
            </Form>
        </Edit>
    )
}
