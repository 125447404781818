import { type FunctionComponent, useState, useEffect, useCallback } from 'react'
import { Input, Tabs, InputProps } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useTranslation } from 'react-i18next'

import textToLocaleValue from '../../../utils/textToLocaleValue'
import type { Language, LocaleValue } from '../../../interfaces'

const { TabPane } = Tabs

type Props = InputProps & {
    value?: string | LocaleValue
    onChange?: (value?: LocaleValue) => void
}

const LocaleTextField: FunctionComponent<Props> = ({ id, value, onChange, ...props }) => {
    const { i18n } = useTranslation()
    const t = useTranslate()
    const [innerValue, setInnerValue] = useState<LocaleValue | undefined>(undefined)

    const languages = i18n.languages ?? []

    useEffect(() => {
        if (typeof value === 'string') {
            const parsedValue = textToLocaleValue(value)
            setInnerValue(parsedValue)
            onChange?.(parsedValue)
        } else if (typeof value === 'object' && value != null) {
            setInnerValue((prev) => (prev === value ? prev : value))
        }
    }, [value, onChange])

    const onInnerChange = useCallback(
        (locale: Language, value?: string) => {
            setInnerValue((prevValue) => {
                const nextValue = !!prevValue && typeof prevValue === 'object' ? { ...prevValue } : {}
                nextValue[locale] = value

                onChange?.(nextValue)
                return nextValue
            })
        },
        [onChange]
    )

    return (
        <Tabs id={id}>
            {languages.map((language) => (
                <TabPane key={language} tab={t(`languages.${language}`)}>
                    <Input
                        {...props}
                        value={innerValue?.[language as Language]}
                        onChange={(event) => onInnerChange(language as Language, event.target.value)}
                    />
                </TabPane>
            ))}
        </Tabs>
    )
}

export default LocaleTextField
