import type { LocaleValue } from '../interfaces'

const textToLocaleValue = (text?: string): LocaleValue | undefined => {
    let value: LocaleValue | undefined
    try {
        if (text) {
            value = JSON.parse(text)
        }
    } catch (error) {
        // Just swallowing errors
    }

    return value
}

export default textToLocaleValue
